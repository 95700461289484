:root {
    --color-primary: #295F92;
    --color-secondary: #455b64;
    --color-background: #f3f3f3;
}

*::selection {
    background-color: var(--color-primary);
    color: #000000;
}

@import url('https://fonts.googleapis.com/css2?family=Oswald:wght@200;300;400;500;600;700&display=swap');
@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@100;300;400;500;700&display=swap");
@import url('https://fonts.googleapis.com/css2?family=Dosis:wght@200;300;400;500;600;700;800&display=swap');

html {
    font-size: 62.5% !important; // 1rem = 10px, 10px/16px = 62.5%
    scroll-behavior: smooth;
    overflow-x: hidden;
}

body {
    font-family: "Roboto", sans-serif;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.8;
    color: #000;
    width: 100%;
    max-width: 100%;
    overflow-x: hidden !important;
}

.header {
  width: 100%;
  max-width: 100%;
  z-index: 99999;
  background-color: #295F92;

  @media only screen and (max-width: 980px) {
      display: none !important;
      overflow-x: hidden !important;
  }

  &_top {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 1rem 0;
      background-color: #295F92;
      z-index: 1000 !important;

      @media only screen and (max-width: 1200px) {
        padding: 1rem 7rem;
    }
  

      &-left {
          display: flex;
          align-items: center;

          &--p {
              font-family: "Roboto", sans-serif;
              font-size: 1.4rem;
              font-weight: 400;
              line-height: 1;
              color: #ffffff;
              margin: 0 1rem;
          }

          &--link{
            font-family: "Roboto", sans-serif;
            font-size: 1.5rem;
            font-weight: 500;
            fill: #295F92;
            color: #295F92;
            background-color: #FFFFFF;
            padding: 1.2rem 2.4rem;
            border-radius: .3rem;
            text-align: center;
            transition: all .3s;

            &:hover{
              text-decoration: none;
              background-color: var(--color-primary);
              color: #fff;
              cursor: pointer;
            }
          }
      }
  }
}

.navbar-active {
  position: fixed !important;
  width: 100%;
  max-width: 100%;
  box-shadow: 0 1rem 2rem rgba(0, 0, 0, 0.12);
  border: 0 !important;
  top: 0 !important;
  left: 0 !important;
  transition: .2s;
  z-index: 1000 !important;
}

.navbar-active .Navbar_main {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}

.modal-active{
  top: 10% !important;
}

.Navbar {
  background: #fff;
  z-index: 10000 !important;
  width: 100%;
  max-width: 100%;
  transition: .5s all ease-in-out;
  padding: 0 !important;

  &_main {
    padding: 2.5rem 0 !important;

    @media only screen and (max-width: 1600px) {
      padding: 1rem 0 !important;
    }

    // @media only screen and (max-width: 1400px) {
    //   margin-left: -10rem !important;
    // }

    @media only screen and (max-width: 990px) {
      margin-left: 0 !important;
    }
  }

  &_image {

    @media only screen and (max-width: 1400px) {
      margin-right: 0 !important;
    }

    &-img {
      width: 30rem;
      height: auto;
      max-width: 100%;

      @media only screen and (max-width: 990px) {
        width: 20rem;
      }
    }
  }

  &_toggler {
    color: var(--color-primary) !important;

    &-icon {
      font-size: 3rem;
    }
  }

  &_nav {
    display: flex !important;
    justify-content: flex-end !important;
    margin-left: auto !important;
    transition: all .5s fade-in;

    @media only screen and (max-width: 990px) {
      padding: 2rem 3rem !important;
    }

    &-item {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      @media only screen and (max-width: 1200px) {
        margin-right: 2px !important;
      }

      @media only screen and (max-width: 990px) {
        justify-content: flex-start !important;
        align-items: flex-start !important;
        text-align: left;
      }
    }

    &-link {
      color: var(--color-secondary);
      font-style: normal;
      font-weight: 500;
      font-size: 1.6rem;
      line-height: 1.396;
      letter-spacing: 0.001em;
      font-family: "Roboto", sans-serif;
      text-transform: none;
      white-space: nowrap;

      @media only screen and (max-width: 1200px) {
        font-size: 1.5rem;
      }

      &:hover {
        width: auto !important;
        color: var(--color-primary);
        cursor: pointer;
      }
    }


    &-image{
      width: 5rem;
      height: 5rem;
      margin-left: 2rem;
      margin-top: -1.5rem;

      @media only screen and (max-width: 990px) {
        display: flex;
        justify-content: flex-end;
      }

      &--img{
        max-width: 100%;
      }
    }
  }
}

.slider2{
  position: relative;
  background-image: url("../img/contactbanner.jpg");
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  z-index: 0;
  border-bottom: 1rem solid #AED1E8;

  &::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: #295F92;
    opacity: 0.5;
    z-index: -1;
    transition: 0.5s;
  }
}


.slider{
  position: relative;
  background-image: url("../img/slider.jpg");
  background-position: center center;
  background-repeat: no-repeat;
  z-index: 0;
  border-bottom: 1rem solid var(--color-primary);

  &::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(3, 3, 3, 0.2) !important;
    z-index: -1;
    transition: 0.5s;
  }

  &_content2{
    padding: 17rem 0;
    padding-bottom: 37rem;
    z-index: 100 !important;

  }

  &_content{
    padding: 14rem;
    padding-bottom: 28rem;
    z-index: 100 !important;

    @media only screen and (max-width: 1400px) {
      padding: 14rem 7rem;
    }

    @media only screen and (max-width: 990px) {
      padding: 14rem 2rem;
    }

    @media only screen and (max-width: 600px) {
      padding: 14rem .5rem;
    }

    &-title{
      color: #FFFFFF;
      font-family: "Oswald", Sans-serif;
      font-size: 6rem;
      font-weight: bold;
      text-transform: uppercase;
      letter-spacing: 1px;
      text-shadow: 0px .4rem 10rem #295F92;
      text-align: center;

      @media only screen and (max-width: 990px) {
        font-size: 6.5rem;
      } 

      @media only screen and (max-width: 768px) {
        font-size: 4rem;
      } 
    }

    &-bg{
      padding: 1rem;
      background-color: #295F92;
      border-radius: 1rem;
      box-shadow: 0px 0px 0px 0px rgba(0,0,0,0.5);
      margin-top: 1rem;
    }

    &-p{
      color: #ffffff;
      font-family: "Roboto", Sans-serif;
      font-size: 2.3rem;
      font-weight: 300;
      line-height: 1.5em;
      letter-spacing: 0px;
      text-align: center;
    }
  }

  &_form{
    background: rgba(56,56,56,0.5);
    padding: 2rem;
    padding-bottom: 4rem !important;
    height: 100%;
    z-index: 100 !important;

    &-label{
       font-family: "Roboto", Sans-serif;
      color: #fff;
      font-size: 1.2rem;
    }

    &-input{
      padding: 1rem;
       font-family: "Roboto", Sans-serif;
      font-style: normal;
      font-weight: 400;
      font-size: 2rem;
      line-height: 1.7;
      color: #000000;
      background: #FFFFFF;
      border-radius: .5rem;
      width: 100%;
      max-width: 100%;
    }

    &-textarea{
      padding: 1rem;
       font-family: "Roboto", Sans-serif;
      font-style: normal;
      font-weight: 400;
      font-size: 2rem;
      line-height: 1.7;
      color: #000000;
      background: #FFFFFF;
      border-radius: .5rem;
      width: 100%;
      max-width: 100%;
      height: 100%;
    }

    &-btn{
      font-style: normal;
      font-weight: 400;
      font-size: 1.4rem;
      font-family: "Roboto", Sans-serif;
      border-radius: .5rem .5rem .5rem .5rem;
      background: var(--color-primary);
      border: .2rem none transparent;
      box-shadow: 0px 0px 0px -.7rem rgba(0,0,0,0);
      padding: 1rem 2rem !important;
      white-space: nowrap;
      color: #fff !important;
      text-transform: none !important;

      @media only screen and (max-width: 1200px) {
        margin-right: 0 !important;
      }

      &:hover {
        background: #fff !important;
        color: var(--color-primary) !important;
        border: 2px solid var(--color-primary);
        transition: all .5s;
        cursor: pointer;
      }
    }
  }
}

.box{
  margin-top: -20rem;

  @media only screen and (max-width: 1400px) {
    margin-top: -10rem;
  }

  &_body{
    background-color: #ffffff;
    box-shadow: 0px 0px 5rem 0px rgba(0,0,0,0.16);
    transition: background 0.3s, border 0.3s, border-radius 0.3s, box-shadow 0.3s;
    position: relative;
    width: 100%;
    padding: 7rem;
    padding-top: 2rem;

    &-title{
      color: #455b64;
      font-family: "Roboto", Sans-serif;
      font-size: 4.5rem;
      font-weight: 900;
      text-transform: uppercase;
      letter-spacing: 1px;
      text-align: center;
      padding: 5rem 0;
    }
  }

  &_content{
    display: flex;
    gap: 3rem;
    margin: 3rem;

    &-icon{
      width: 30%;

      &--img{
        width: 100%;
        height: auto;
        max-width: 100%;
      }
    }

    &-title{
      margin-bottom: 1rem;
      color: #455b64;
      font-family: "Roboto", Sans-serif;
      font-size: 1.7rem;
      font-weight: 900;
      text-transform: uppercase;
      letter-spacing: .1rem;
    }

    &-p{
      color: #455b64;
      font-family: "Roboto", Sans-serif;
      font-size: 1.6rem;
      font-weight: 300;
      letter-spacing: 0px;
      line-height: 1.4;
    }
  }
}

.swiper {
  width: 100%;
  padding-top: 50px;
  padding-bottom: 50px;
}

.swiper-slide {
  background-position: center;
  background-size: cover;
  width: 300px;
  height: 300px;
}

.swiper-slide img {
  display: block;
  width: 100%;
}

.menu{

  &_slider{
    width: 100%;
  }

  &_image{
    // width: 100%;
    // transition-duration: 0ms;
    // transform: translate3d(0px, 0px, -211.091px) rotateX(0deg) rotateY(-105.545deg);
    // z-index: -1;
    // margin-right: 1rem;

    @media only screen and (max-width: 990px) {
      margin-top: 4rem !important;
      margin-bottom: 4rem !important;
    }

    &-img{
      width: 100%;
      max-width: 100%;
      height: 10rem;
    }
  }
}

.swiper {
  width: 100%;
  height: 400px;
  padding-bottom: 2rem;
}

.swiper-slide img {
  height: 100%;
  width: 200px;
  max-width: 100%;
  object-fit: cover;
}

.swiper-scrollbar {
  margin-top: 3rem;
}


.swiper-pagination-bullet{
  width: .5rem !important;
  height: .5rem !important;
  bottom: -20% !important;
  background-color: #fff !important;
  opacity: 1 !important;
}

.swiper-pagination-bullet-active{
  width: .5rem !important;
  height: .5rem !important;
  background-color: var(--color-primary) !important;
}

.swiper-pagination {
  position: relative !important;
  margin-top: 1.2rem !important;
}

.swiper-button-next{
  color: var(--color-secondary) !important;
}

.swiper-button-prev{
  color: var(--color-secondary) !important;
}

.menu{

  &_body{
    padding: 14rem 0;
  }

  &_content{

    &-title{
      color: #455b64;
      font-family: "Oswald", Sans-serif;
      font-size: 3rem;
      font-weight: 900;
      text-transform: uppercase;
      letter-spacing: 1px;
    }

    &-divider{
      margin: 2rem 0;
      width: 10%;
      height: .3rem;
      color: var(--color-primary);
      background-color: var(--color-primary);
    }

    &-p{
      color: #455b64;
      font-family: "Roboto", Sans-serif;
      font-size: 1.6rem;
      font-weight: 300;
      line-height: 1.6;
      letter-spacing: 0px;
    }

    &-link{
      font-family: "Roboto", Sans-serif;
      font-size: 1.6rem;
      font-weight: 500;
      background-color: var(--color-primary);
      display: inline-block;
      line-height: 1;
      padding: 1.2rem 2.4rem;
      border-radius: .3rem;
      color: #fff;
      fill: #fff;
      text-align: center;
      transition: all .3s;
      margin-top: 2rem;

      &:hover {
        background: #fff !important;
        color: var(--color-primary) !important;
        border: 2px solid var(--color-primary);
        transition: all .5s;
        cursor: pointer;
      }
    }

    &-video{
      width: 100%;
      height: 100%;
      max-width: 100%;

      @media only screen and (max-width: 768px) {
          width: 90vw;
          margin: 1rem 0;
      }
    }
  }
}


.stripe{
  background-color: #AED1E8;

  &_body{
    padding: 7rem 0;
    padding-bottom: 10rem;
  }

  &-hover{
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    right: 0;
    padding: 10rem 5rem;
    background: rgba(83,90,119,0.84);
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    opacity: 0;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-transition: all .5s;
    -o-transition: all .5s;
    transition: all .5s;
  
    &:hover {
      cursor: pointer;
      opacity: 1;
    }
  }

  &_image{
    position: relative;
    width: 100%;
    height: 100%;

    &-1{
      background-image: url("../img/s1.png");
      background-repeat: no-repeat;
      background-size: cover;
      width: 100%;
      max-width: 100%;
      height: 100%;
    }

    &-2{
      background-image: url("../img/s2.png");
      background-repeat: no-repeat;
      background-size: cover;
      width: 100%;
      max-width: 100%;
      height: 100%;
    }

    &-3{
      background-image: url("../img/s3.png");
      background-position: center center;
      background-repeat: repeat;
      background-size: cover;
      width: 100%;
      max-width: 100%;
      height: 100%;
    }

    &-4{
      background-image: url("../img/s4.png");
      background-repeat: no-repeat;
      background-size: cover;
      width: 100%;
      max-width: 100%;
      height: 100%;
    }

    &-title{
      display: flex;
      justify-content: center;
      align-items: center;
      text-align: center;
      color: #AED1E8;
      font-family: "Roboto", Sans-serif;
      font-size: 6rem;
      font-weight: 700;
      text-transform: uppercase;
      padding: 24rem 0;
    }
  }

  &_hover{

    &-title{
      margin-bottom: 6rem;
      color: #FFFFFF;
      font-family: "Oswald", Sans-serif;
      font-size: 2.8rem;
      text-transform: uppercase;
    }

    &-p{
      font-family: "Roboto", Sans-serif;
      font-size: 1.5rem;
      font-weight: 400;
      line-height: 1.6em;
      color: #fff;
    }

    &-link{
      display: inline-block;
      line-height: 1;
      font-size: 1.5rem;
      padding: 1.2rem 2.4rem;
      color: #fff;
      fill: #fff;
      text-align: center;
      transition: all .3s;
      margin-left: 0;
      font-family: "Oswald", Sans-serif;
      font-weight: 400;
      text-transform: uppercase;
      letter-spacing: 1.3px;
      background-color: rgba(255,255,255,0.25);
      border-width: 0px;
      border-radius: 0px;
    }
  }
}

.link{
  height: 100%;
  width: 100%;
  max-width: 100%;

  &:hover{
    text-decoration: none;
    cursor: pointer;
  }
}

.patners{
  padding: 0;

  &_image{

    &-img{
      width: 100%;
      max-width: 100%;
      height: auto
    }
  }

}


.carousel {
  width: 100%;
}

.slide-box {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.slide-box img {
  width: 100%;
  max-width: 100%;
  height: 100%;
}

@media (min-width: 576px) and (max-width: 767.98px) {
  .slide-box img {
    -ms-flex: 0 0 50%;
    flex: 0 0 50%;
    max-width: 50%;
  }
}

@media (min-width: 768px) and (max-width: 991.98px) {
  .slide-box img {
    -ms-flex: 0 0 33.3333%;
    flex: 0 0 33.3333%;
    max-width: 33.3333%;
  }
}

@media (min-width: 992px)
{
  .slide-box img {
    -ms-flex: 0 0 25%;
    flex: 0 0 25%;
    max-width: 25%;
  }
}

.carousel-caption {
  background-color: rgba(0, 0, 0, 0.5);
  padding: 20px;
  border-radius: .5rem;
}

.footer{
  width: 100%;
  max-width: 100%;
  border-style: solid;
  border-width: 1px 0px 0px 0px;
  border-color: rgba(3,53,55,0.2);
  transition: background 0.3s, border 0.3s, border-radius 0.3s, box-shadow 0.3s;
  margin-top: 0px;
  margin-bottom: 0px;
  margin-top: 21rem;
  
  &_row{
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    max-width: 100%;
    padding: 2rem 7rem;

    @media only screen and (max-width: 1400px) {
      padding: 2rem 5rem;
    }

    @media only screen and (max-width: 990px) {
      padding: 2rem .5rem;
      flex-direction: column;
    }
  }

  &_social{
    margin-top: 2rem;

    &-icon{
      color: #295F92;
      transition: color 0.3s;
      font-size: 1.8rem;
    }
  }

  &_logo{
    flex: 1;

    &-img{
      width: 30rem;
      height: auto;
      max-width: 100%;

      @media only screen and (max-width: 1400px) {
        width: auto;
      }
    }
  }

  &_links{
    flex: 3;

    &-ul{
      list-style: none;
      display: flex;
      justify-content: center;
      align-items: center;

      @media only screen and (max-width: 990px) {
        flex-wrap: wrap;
      }
    }

    &-li{

      &--link{
        color: #033537;
        fill: #033537;
        padding-left: 2rem;
        padding-right: 2rem;
        font-family: "Dosis", Sans-serif;
        font-size: 2rem;
        font-weight: 300;

        &:hover{
          text-decoration: none;
          color: #10ccae;
          fill: #10ccae;
          cursor: pointer;
        }
      }
    }
  }

  &_devis{
    flex: 1;

    @media only screen and (max-width: 990px) {
      margin-top: 2rem;
    }

    &-link{
      font-family: "Dosis", Sans-serif;
      font-size: 1.8rem;
      font-weight: 400;
      text-transform: uppercase;
      fill: #295F92;
      color: #295F92;
      background-color: #AED1E8;
      padding: 1.5rem 3rem;
      border-radius: 0px 0px 0px 0px;
      text-align: center;
      transition: all .3s;
      white-space: nowrap;

      &:hover {
        text-decoration: none;
        background: var(--color-primary) !important;
        color: #fff !important;
        border: 2px solid #fff;
        transition: all .3s;
        cursor: pointer;
      }
    }

    &-link2{
      font-family: "Dosis", Sans-serif;
      font-size: 2.5rem;
      font-weight: 400;
      text-transform: uppercase;
      fill: #fff;
      color: #fff;
      background-color: #295F92;
      padding: 1.5rem 3rem;
      border-radius: 0px 0px 0px 0px;
      text-align: center;
      transition: all .3s;
      margin-top: 2rem;
      white-space: nowrap;

      &:hover {
        text-decoration: none;
        background: var(--color-primary) !important;
        color: #fff !important;
        border: 2px solid #fff;
        transition: all .3s;
        cursor: pointer;
      }
    }
  }

  &_bottom{
    background-color: #295F92;

    &-row{
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: .5rem 7rem;

      @media only screen and (max-width: 1400px) {
        padding: 2rem 5rem;
      }
  
      @media only screen and (max-width: 990px) {
        padding: 2rem .5rem;
        flex-direction: column;
      }
    }

    &-p{
      font-family: "Dosis", Sans-serif;
      font-size: 1.6rem;
      font-weight: 300;
      color: #ffffff;
      transition: color 0.3s;
      display: flex;
      align-items: center;
    }

    &-line{
      font-family: "Dosis", Sans-serif;
      font-size: 1.6rem;
      font-weight: 300;
      color: #ffffff;
      transition: color 0.3s;
      display: flex;
      align-items: center;
      margin-top: 1rem;

    }

    &-links{
      display: flex;
      gap: 2rem;
      align-items: center;

      @media only screen and (max-width: 1200px) {
        justify-content: center;
        flex-wrap: wrap;
      }
    }

    &-link{
      font-family: "Dosis", Sans-serif;
      font-size: 1.6rem;
      font-weight: 300;
      color: #ffffff;
      transition: color 0.3s;
      display: flex;
      align-items: center;
    }
  }
}

.eye{
  font-size: 4rem;
  color: #fff;
}

.banner{

  &_bg{

    &-row{
      display: flex;
      padding: 0 14rem;
    }

    &-content2{
      background-color: #AED1E8;
      transition: background 0.3s, border 0.3s, border-radius 0.3s, box-shadow 0.3s;
      padding: 20% 10% 5% 10%;
      position: relative;

      @media only screen and (max-width: 990px) {
        padding-right: 5%;
        padding-top: 5%;
        padding-bottom: 20%;
      }
    }

    &-content{
      background-color: #295F92;
      transition: background 0.3s, border 0.3s, border-radius 0.3s, box-shadow 0.3s;
      padding: 5% 30% 10% 10%;
      position: relative;

      @media only screen and (max-width: 990px) {
        padding-right: 5%;
      }

      &--title{
        color: #ffffff;
        font-family: "Oswald", Sans-serif;
        font-size: 5rem;
        font-weight: 900;
        letter-spacing: 1px;
        margin-bottom: 2rem;
      }

      &--title2{
        color: #ffffff;
        font-family: "Oswald", Sans-serif;
        font-size: 8rem;
        font-weight: 900;
        letter-spacing: 1px;
        margin-bottom: 2rem;
      }

      &--p{
        color: #ffffff;
        font-family: "Roboto", Sans-serif;
        font-size: 1.6rem;
        font-weight: 300;
        line-height: 1.8em;
        letter-spacing: 0px;
        margin-bottom: 2rem;
      }

      &--p2{
        color: #ffffff;
        font-family: "Helvetica", Sans-serif;
        font-size: 1.8rem;
        font-weight: 300;
        line-height: 1.8em;
        letter-spacing: 1.5px;
        margin-bottom: 2rem;
      }
    }

    &-image{
      margin-top: 30%;

      @media only screen and (max-width: 1400px) {
        display: flex;
        justify-content: flex-end;
        margin-top: 50%;
      }

      @media only screen and (max-width: 990px) {
        margin-top: -5rem;
      }

      &--img{
        width: 100%;
        height: 51.58%;
        max-width: 190rem;
        margin-left: -25rem;

        &-20{
          width: 100%;
          height: 51.58%;
          max-width: 190rem;
          margin-left: -20rem;
        }
      }
    }
  }
}

.brands{
  padding: 7rem 0;

  &_image{
    display: flex;
    justify-content: center;
    width: 60%;
    height: 106.91%;
    max-width: 100%;

    @media only screen and (max-width: 990px) {
     width: 100%;
     margin: 2rem 0;
    }

    &-img{
      width: 100%;
    }
  }
}

.sidemenu{
  padding: 5rem 0;

  &_image{
    width: 100%;
    max-width: 100%;
    height: 100%;

    &-1{
      position: relative;
      background-image: url("../img/side1.png");
      background-position: bottom center;
      background-size: cover;
      width: 100%;
      max-width: 100%;
      height: 100%
    }

    &-2{
      position: relative;
      background-image: url("../img/side2.png");
      background-position: bottom center;
      background-size: cover;
      width: 100%;
      max-width: 100%;
      height: 100%
    }

    &-3{
      position: relative;
      background-image: url("../img/9.jpg");
      background-position: bottom center;
      background-size: cover;
      width: 100%;
      max-width: 100%;
      height: 100%
    }

    &-4{
      position: relative;
      background-image: url("../img/s5.png");
      background-position: bottom center;
      background-size: cover;
      width: 100%;
      max-width: 100%;
      height: 100%
    }
  }

  &_content{
    background-color: #B5D8EF;
    transition: background 0.3s, border 0.3s, border-radius 0.3s, box-shadow 0.3s;
    // margin: 0% 45% 0% 0%;
    // --e-column-margin-right: 45%;
    // --e-column-margin-left: 0%;
    padding: 4rem;

    &-title{
      color: #295F92;
      font-family: "Oswald", Sans-serif;
      font-size: 3rem;
      font-weight: bold;
      line-height: 1.3em;
      letter-spacing: 1px;
    }

    &-p{
      color: #000000;
      font-family: "Roboto", Sans-serif;
      font-size: 1.6rem;
      font-weight: 400;
      line-height: 1.8em;
      letter-spacing: 0px;
    }

    &-p2{
      color: #000000;
      font-family: "Roboto", Sans-serif;
      font-size: 2rem;
      font-weight: 400;
      line-height: 1.8em;
      letter-spacing: 0px;
      margin-top: 1rem;
    }

    &-logo{
      display: flex;
      justify-content: flex-start;
      width: 100%;
      max-width: 100%;
      height: auto;
    }

    &-li{
      color: #000000;
      font-family: "Roboto", Sans-serif;
      font-size: 2rem;
      font-weight: 400;
      line-height: 1.8em;
      letter-spacing: 0px;
      white-space: nowrap;

      @media only screen and (max-width: 1700px) {
        white-space: normal;
      }
    }

    &-ul{
      list-style: none;
      padding-left: 0;
    }

    &-icon{
      color: #295F92;
      font-size: 1.6rem;
      margin-right: .5rem;
    }
  }
}

.bg-primary{
  background-color: var(--color-primary) !important;
}

.temoignage{
  background-color: #B5D8EF;

  &_content{
    transition: background 0.3s, border 0.3s, border-radius 0.3s, box-shadow 0.3s;
    padding: 10rem 0px 10rem 0px;
    padding-top: 5rem;

    &-title{
      color: #295F92;
      font-family: "Oswald", Sans-serif;
      font-size: 5rem;
      font-weight: 900;
      letter-spacing: 1px;
      text-align: center;
      padding: 4rem 0;
    }
  }
}


.demo-gallery > ul {
  width: 100% !important;
  margin-bottom: 0;
  padding-left: 15px;

  @media only screen and (max-width: 1200px) {
    margin: 0 auto;
  }
}

.demo-gallery > ul > li {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 15px;
  width: 25rem;
  max-width: 100%;
  height: 25rem;
  display: inline-block;
  margin-right: auto;
  margin-left: auto;
  list-style: outside none none;

  @media only screen and (max-width: 1200px) {
    width: 30rem;
  }

  @media only screen and (max-width: 768px) {
    width: 100%;
  }
}

.demo-gallery > ul > li a {
  border: 3px solid #FFF;
  border-radius: 3px;
  display: block;
  overflow: hidden;
  position: relative;
  float: left;
}

.demo-gallery > ul > li a > img {
  -webkit-transition: -webkit-transform 0.15s ease 0s;
  -moz-transition: -moz-transform 0.15s ease 0s;
  -o-transition: -o-transform 0.15s ease 0s;
  transition: transform 0.15s ease 0s;
  -webkit-transform: scale3d(1, 1, 1);
  transform: scale3d(1, 1, 1);
  width: 25rem;
  max-width: 100%;
  height: 25rem;
  object-fit: cover;
}

// .demo-gallery > ul > li a:hover > img {
//   -webkit-transform: scale3d(1.1, 1.1, 1.1);
//   transform: scale3d(1.1, 1.1, 1.1);
// }

// .demo-gallery > ul > li a:hover .demo-gallery-poster > img {
//   opacity: 1;
// }

.demo-gallery > ul > li a .demo-gallery-poster {
  background-color: rgba(0, 0, 0, 0.1);
  bottom: 0;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  -webkit-transition: background-color 0.15s ease 0s;
  -o-transition: background-color 0.15s ease 0s;
  transition: background-color 0.15s ease 0s;
}

.demo-gallery > ul > li a .demo-gallery-poster > img {
  left: 0%;
  margin-left: 0;
  margin-top: 0;
  opacity: 0;
  position: absolute;
  top: 50%;
  -webkit-transition: opacity 0.3s ease 0s;
  -o-transition: opacity 0.3s ease 0s;
  transition: opacity 0.3s ease 0s;
  object-fit: cover;
}

// .demo-gallery > ul > li a:hover .demo-gallery-poster {
//   background-color: rgba(0, 0, 0, 0.5);
// }

.demo-gallery .justified-gallery > a > img {
  -webkit-transition: -webkit-transform 0.15s ease 0s;
  -moz-transition: -moz-transform 0.15s ease 0s;
  -o-transition: -o-transform 0.15s ease 0s;
  transition: transform 0.15s ease 0s;
  -webkit-transform: scale3d(1, 1, 1);
  transform: scale3d(1, 1, 1);
  height: 100%;
  width: 100%;
  object-fit: cover;
}

// .demo-gallery .justified-gallery > a:hover > img {
//   -webkit-transform: scale3d(1.1, 1.1, 1.1);
//   transform: scale3d(1.1, 1.1, 1.1);
// }

// .demo-gallery .justified-gallery > a:hover .demo-gallery-poster > img {
//   opacity: 1;
// }

.demo-gallery .justified-gallery > a .demo-gallery-poster {
  background-color: rgba(0, 0, 0, 0.1);
  bottom: 0;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  -webkit-transition: background-color 0.15s ease 0s;
  -o-transition: background-color 0.15s ease 0s;
  transition: background-color 0.15s ease 0s;
}

.demo-gallery .justified-gallery > a .demo-gallery-poster > img {
  left: 0%;
  margin-left: 0;
  margin-top: 0;
  opacity: 0;
  position: absolute;
  top: 50%;
  -webkit-transition: opacity 0.3s ease 0s;
  -o-transition: opacity 0.3s ease 0s;
  transition: opacity 0.3s ease 0s;
  object-fit: cover;
}

.demo-gallery .justified-gallery > a:hover .demo-gallery-poster {
  background-color: rgba(0, 0, 0, 0.5);
}

.demo-gallery .video .demo-gallery-poster img {
  height: 100%;
  margin-left: -24px;
  margin-top: -24px;
  opacity: 0.8;
  width: 100%;
  object-fit: cover;
}

.demo-gallery.dark > ul > li a {
  border: 3px solid #04070a;
}

.gallery{

  &_image{
    object-fit: cover !important;
    width: 10rem;
    height: 10rem;
  }

  &_height{
    // height: 52.5rem !important;
  }

  &_hover{
    transition: all .3s ease-in-out;

    &:hover{
      -webkit-transform: scale3d(0.9, 0.9, 0.9) !important;
      transform: scale3d(0.9, 0.9, 0.9) !important;
    }
  }
}

.avant{

  &_image{

    &-img{
      width: 100%;
      max-width: 100%;
      height: auto;
    }

    &-img2{
      margin-top: 5rem;
      width: 40%;
      height: auto;
      max-width: 100%;
    }
  }
}

strong{
  font-weight: 900 !important;
}

.menu2{
  padding: 0 14rem;
  margin-top: -17rem;

  @media only screen and (max-width: 1700px) {
    margin-top: -7rem;
  }

  @media only screen and (max-width: 1200px) {
    margin-top: 0rem;
    padding: 0 .5rem;
  }
}

.menu3{
  padding: 0 10rem;

  &_table{

    &-img{
      width: 100%;
      max-width: 100%;
      height: auto;
    }
  }

  &_image{

    &-img{
      width: 70%;
      max-width: 100%;
      height: 54%;
    }
  }

  &_content{

    &-title{
      color: #000000;
      font-family: "Roboto", Sans-serif;
      font-size: 1.6rem;
      font-weight: 500;
      line-height: 1.8em;
      letter-spacing: 0px;
      text-transform: uppercase;
    }
  }
}

.underline{
  text-decoration: underline;
}

.w-60{
  width: 60% !important;
}

.reservation{
  padding: 7rem 0 !important;

  &_steps{
    display: flex;
    justify-content: space-around;
    align-items: center;
  }

  &_step{
    background: #fff;
    border: 1px solid var(--color-primary);
    width: 3rem;
    height: 3rem;
    border-radius: 50%;
    display: flex;
    flex-direction: column;
    align-items: center;
    color: #fff;

    &-active{
      background: var(--color-primary);
      color: #fff;
    }

    &-active2{
      background: var(--color-primary);
      color: #fff;
    }

    &-content{
      display: flex;
      justify-content: center;
      margin: 0 auto;
    }

    &-number{
      padding: .3rem 1.3rem;
      display: flex;
      justify-content: center;
      align-items: center;
      text-align: center;

      &--p{
        font-family: 'Poppins', sans-serif;
        font-style: normal;
        font-weight: 700;
        font-size: 1.3rem;
        line-height: 1.7;
        display: flex;
        justify-content: center;
        align-items: center;
        text-align: center;
        color: var(--color-primary);
        margin-left: -.2rem !important;
      }
    }

    &-bottom{
      font-family: 'Work Sans';
      font-style: normal;
      font-weight: 400;
      font-size: 2rem;
      line-height: 1.7;
      display: flex;
      align-items: center;
      text-align: center;
      color: #000000;
      width: 100% !important;
      white-space: nowrap;

      @media only screen and (max-width: 1200px) {
        white-space: normal;
      }

      @media only screen and (max-width: 600px) {
        font-size: 1.6rem;
        line-height: 1.2;
      }
    }

    &-line{
      color: #F4E6D9;
      width: 100%;
      max-width: 100%;
      height: .8rem;
    }
  }

  &_form{
    padding-top: 3rem;

    &-title{
      font-family: "PT Sans Caption", Sans-serif;
      font-size: 2rem;
      font-style: italic;
      color: #363636;
      font-weight: 700;
    }

    &-label{
      font-family: "PT Sans Caption", Sans-serif;
      font-size: 2rem;
      color: #363636;
      font-weight: 900;
      padding-left: .5rem;
      margin: .2rem 0;
    }

    &-input{
      font-family: "PT Sans Caption", Sans-serif;
      font-style: normal;
      font-weight: 400;
      font-size: 2rem;
      color: #AEABAB;
      float: none;
      font-size: 1.8rem;
      border: 1px solid #333;
      padding: 0 1rem;
      height: 4.5rem;
      width: 100%;
      max-width: 100%;
      display: flex;
      justify-content: center;
    }

    &-checkbox{
      height: 3rem;
      width: 3rem;
      max-width: 100%;
      margin: 1rem;
      display: flex;
      justify-content: center;
    }

    &-button{
      font-family: "Oswald", Sans-serif;
      text-transform: uppercase;
      letter-spacing: 3px;
      border-radius: 0px 0px 0px 0px;
      font-size: 1.6rem;
      padding: 2rem;
      background: var(--color-primary);
      border: .2rem none transparent;
      white-space: nowrap;
      color: #fff !important;
      text-transform: uppercase !important;
      width: 100%;
      max-width: 100%;
      margin-top: 2rem;

      &:hover {
        background: #fff !important;
        color: var(--color-primary) !important;
        border: 2px solid var(--color-primary);
        transition: all .5s;
        cursor: pointer;
      }
    }

    &-p1{
      font-family: 'Work Sans';
      font-style: normal;
      font-weight: 400;
      font-size: 2rem;
      line-height: 1.5;
      text-align: center;
      color: #000000;    
    }

    &-radio{
      position: relative;
      margin: .5rem .5rem .5rem .5rem !important;
      display: inline-block;
      background-color: #fff;
      display: block;
      margin: 0 auto;
      border: 1px solid #fff;
      border-radius: 3px;
      padding: 2rem 2rem 1.8rem 2rem;
      transition: all 0.5s;
      text-align: center;
      margin: 1rem;
      border: 1px solid #EAC400;
      height: 100%;

      &:hover{
        background-color: #F4E6D9;
        cursor: pointer;
      }

      &--input{
        position: absolute;
        left: 50%;
        transform: translate(-50%, -30%);
        width: 100%;
        max-width: 100%;
        height: 45rem;
        z-index: 100;
        visibility: hidden;
      }

      &--img{
        width: 28.9rem;
        height: 17.9rem;
      }

      &--img1{
        width: 36.9rem;
        height: 21.1rem;
      }

      &--p{
        font-family: 'Work Sans';
        font-style: normal;
        font-weight: 700;
        font-size: 1.5rem;
        line-height: 1.5;
        text-align: center;
        color: #000000;        
      }
    }
  }

  &_menu{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 3rem;
  }

  &_buttons{
    // margin-top: 2rem !important;
  }
}


.form-2{
  display: none;
}

.form-3{
  display: none;
}


.form-4{
  display: none;
}

::placeholder{
  color: #333;
}

.reservation_step-active .reservation_step-number--p {
  color: #fff !important;
}

.contact{
  padding: 7rem 0;

  &_content{
    background-color: #ebebeb;
    transition: background 0.3s, border 0.3s, border-radius 0.3s, box-shadow 0.3s;
    margin: 0% 45% 0% 0%;
    --e-column-margin-right: 45%;
    --e-column-margin-left: 0%;
    padding: 6rem;

    @media only screen and (max-width: 990px) {
      width: 100%;
      margin-top: 2rem;
    }

    &-title{
      color: #455b64;
      font-family: "Oswald", Sans-serif;
      font-size: 3rem;
      font-weight: 900;
      text-transform: uppercase;
      letter-spacing: 1px;
    }

    &-divider{
      margin: 2rem 0;
      width: 10%;
      height: .3rem;
      color: #ffba00;
      background-color: #ffba00;
    }

    &-p{
      color: #455b64;
      font-family: "Roboto", Sans-serif;
      font-size: 1.8rem;
      font-weight: 300;
      line-height: 1.7;
      letter-spacing: 0px;
      padding: 1.5rem 0;
    }

    &-link{
      font-family: "Roboto", Sans-serif;
      font-weight: 300;
      color: #455b64;
      transition: color 0.3s;
      font-size: 2rem;
      padding: .7rem 0;
      display: block
    }

    &-button{
      font-family: "Oswald", Sans-serif;
      font-weight: 300;
      text-transform: uppercase;
      letter-spacing: 2px;
      background-color: #295F92;
      border-radius: 0px 0px 0px 0px;
      font-size: 1.6rem;
      padding: 1.5rem 3rem;
      width: 100%;
      color: #fff;
      margin: 3rem auto;
      display: flex;
      justify-content: center;

      &:hover {
        text-decoration: none;
        background: #fff !important;
        color: var(--color-primary) !important;
        border: 2px solid var(--color-primary);
        transition: all .5s;
        cursor: pointer;
      }
    }
  }
}

.mention-title{
  color: #000;
  font-family: "Roboto", Sans-serif;
  font-size: 4.8rem;
  font-weight: 400;
  text-transform: normal;
  letter-spacing: 1px;
}

.mention-p{
  color: #000;
  font-family: "Roboto", Sans-serif;
  font-size: 2rem;
  font-weight: 400;
  text-transform: uppercase;
  letter-spacing: 1px;
}

.active-clr {
  color: #10ccae !important;
  font-weight: 400;
}

#fixphone {
  position: fixed;
  bottom: 2%;
  right: 1.5rem;
  background: var(--color-primary);
  border: 2px solid var(--color-primary);
  color: #fff;
  font-size: 1.8rem;
  padding: .5rem 1rem;
  border-radius: .5rem;
  transition: 0.3s;
  text-decoration: none;
  z-index: 10000 !important;

  @media only screen and (max-width: 768px) {
    bottom: 3%;
  }
}

#fixphone:hover {
  background: rgba(0, 0, 0, 0);
  color: var(--color-primary);
  text-decoration: none;
}

.in-1700{

  @media only screen and (max-width: 1700px) {
    display: flex;
    justify-content: flex-end;
  }
}

.contact-nav{
  display: flex;
  justify-content: flex-end;
  align-items: center;

  @media only screen and (max-width: 990px) {
    justify-content: flex-start;
    margin-top: 4rem;
  }
}

.container{

  @media only screen and (min-width: 2200px) {
    width: 80% !important;
    max-width: 80% !important;
  }
}

.owl-carousel .owl-item img {
  display: block;
  width: 100%;
  height: 40rem;
  object-fit: cover;
}


// Imports
@import "bootstrap";


.dropdown.active {
  a.dropdown-item{
    color: $body-color!important;
    &.active{
      color: $white!important;
    }
  }
}
